/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import WithKarolineOgThomasBackground from "../components/withKarolineOgThomasBackground"

import "./layout.css"

const Layout = ({ children, confetti }) => {
  return (
    <WithKarolineOgThomasBackground className="karolineogthomasiheis">
      <div style={{ height: "1rem" }}></div>
      <div className="layout">
        <main>{children}</main>
        <div style={{ margin: "auto", width: 0 }} ref={confetti} />
        <footer>Karoline og Thomas</footer>
      </div>
      <div style={{ height: "1rem" }}></div>
    </WithKarolineOgThomasBackground>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
