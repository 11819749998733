import React, { useEffect, useState } from "react"
import "./savethedatecalendar.css"
import { confetti } from "dom-confetti"

export const SaveTheDateCalendar = ({ anchor }) => {
  const [count, increaseCount] = useState(0)
  const config = {
    angle: "90",
    spread: "45",
    startVelocity: "59",
    elementCount: "90",
    dragFriction: "0.11",
    duration: "2330",
    stagger: "0",
    width: "10px",
    height: "15px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"],
  }
  useEffect(() => {
    count && anchor && confetti(anchor.current, config)
  }, [count])
	
  return (
    <div className="calendar">
      <div className="month monthYear">
        <h3>August, 2020</h3>
      </div>
      <div className="month">
        <div className="weekDays">
          <div className="dayName">M</div>
          <div className="dayName">T</div>
          <div className="dayName">O</div>
          <div className="dayName">T</div>
          <div className="dayName">F</div>
          <div className="dayName">L</div>
          <div className="dayName">S</div>
        </div>
      </div>
      <div className="month">
        <div className="week">
          <div style={{ width: "100%" }} />
          <div className="day">1</div>
          <div className="day">2</div>
        </div>
        <div className="week">
          <div className="day">3</div>
          <div className="day">4</div>
          <div className="day">5</div>
          <div className="day">6</div>
          <div className="day">7</div>
          <div className="day">8</div>
          <div className="day">9</div>
        </div>
        <div className="week">
          <div className="day">10</div>
          <div className="day">11</div>
          <div className="day">12</div>
          <div className="day">13</div>
          <div className="day">14</div>
          <div className="day" >
            <span role="img" aria-label="Hjerte for den 15. August">
              <svg class="heart" viewBox="0 0 32 29.6" onClick={() => increaseCount(count + 1)}>
                <path
                  d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
	c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
                />
              </svg>
            </span>
          </div>
          <div className="day">16</div>
        </div>
        <div className="week">
          <div className="day">17</div>
          <div className="day">18</div>
          <div className="day">19</div>
          <div className="day">20</div>
          <div className="day">21</div>
          <div className="day">22</div>
          <div className="day">23</div>
        </div>
        <div className="week">
          <div className="day">24</div>
          <div className="day">25</div>
          <div className="day">26</div>
          <div className="day">27</div>
          <div className="day">28</div>
          <div className="day">29</div>
          <div className="day">30</div>
        </div>
        <div className="week">
          <div className="day">31</div>
          <div style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  )
}
