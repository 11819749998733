import React, { useRef } from "react"

import Layout from "../components/layout"
import { SaveTheDateCalendar } from "../components/savethedatecalendar"
import SEO from "../components/seo"
import { ConfettiBangbang } from "../components/confetti"

const IndexPage = () => {
  const confettiAnchor = useRef(null)
  return (
    <Layout confetti={confettiAnchor}>
      <SEO title="Brøllup" />
      <h1>VI GIFTER OSS</h1>
      <ConfettiBangbang anchor={confettiAnchor} />
      <h2>Hold av datoen!</h2>
      <SaveTheDateCalendar anchor={confettiAnchor} />

      <p>
        Den 15. august 2020 er den store dagen, og vi håper at du vil dele dagen
        med oss. Mer informasjon kommer senere når alt er litt mer spikra!
      </p>
    </Layout>
  )
}

export default IndexPage
